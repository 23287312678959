import React from 'react'
import { Layout, Menu } from 'antd'
import logo from '../assets/whiteLogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFileCircleCheck, faMessage, faQuestionCircle, faRightFromBracket, faShield, faTicket, faToolbox, faUser } from '@fortawesome/free-solid-svg-icons';
import { Outlet, useNavigate } from 'react-router-dom';
const { Content, Sider } = Layout;

const Index = () => {
    const navigate = useNavigate();

    const items = [
        {
            key: "1",
            icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faUser} />,
            label: "User",
            style: { fontSize: "14px", display: "flex" },
            onClick: () => navigate("/admin/user"),
        },
        {
            key: "2",
            icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faTicket} />,
            label: "Tickets",
            style: { fontSize: "14px", display: "flex" },
            onClick: () => navigate("/admin/ticket"),
        },
        {
            key: "3",
            icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faToolbox} />,
            label: "CMS",
            style: { fontSize: "14px"},
            children:[
                {
                    key: "4",
                    icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faFileCircleCheck} />,
                    label: "Terms and Condition",
                    style: { fontSize: "14px",},
                    onClick: () => navigate("/admin/terms"),
                },
                {
                    key: "5",
                    icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faShield} />,
                    label: "Privacy Policy",
                    style: { fontSize: "14px",},
                    onClick: () => navigate("/admin/privacy"),
                },
                {
                    key: "6",
                    icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faQuestionCircle} />,
                    label: "FAQ",
                    style: { fontSize: "14px",},
                    // onClick: () => navigate("/admin/faq"),
                },
                {
                    key: "7",
                    icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faMessage} />,
                    label: "About Us",
                    style: { fontSize: "14px",},
                    onClick: () => navigate("/admin/about"),
                },
            ]
        },
        {
            key: "8",
            icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faBook} />,
            label: "Advertisers",
            style: { fontSize: "14px", display: "flex" },
            onClick: () => navigate("/admin/advertise"),
        },
        {
            key: "9",
            icon: <FontAwesomeIcon className="text-[12px] mr-1" icon={faRightFromBracket} />,
            label: "Logout",
            style: { fontSize: "14px", display: "flex" },
            onClick: () => {navigate("/"); localStorage.removeItem('token')},
        },
    ]

  return (
    <Layout style={{ minHeight: "100vh" }}>
        <Sider className='py-7' style={{backgroundColor: "#307FE2"}}>
            <img className="w-[10rem] mx-auto mb-10" src={logo} alt="" />
            <Menu
                theme="dark"
                defaultSelectedKeys={["1"]}
                mode="inline"
                style={{ backgroundColor: "#307FE2" }}
                items={items}
            />
        </Sider>
        <Content className='p-10' style={{ backgroundColor: "#F5F5F5" }}>
        {/* For Managing Component Change within the Nested Routes Outlet is used*/}
            <Outlet />
        </Content>
    </Layout>
  )
}

export default Index