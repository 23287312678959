import { SendOutlined, SmileFilled } from "@ant-design/icons/lib/icons";
import { Badge, Button, Card, Col, Empty, Image, Row, Spin } from "antd";
import EmojiPicker from "emoji-picker-react";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import StartChat from "../assets/StartChat.svg";
import { io } from "socket.io-client";
import { Message } from "./Message";
import axios from "axios";
import { useParams } from "react-router-dom";

const Chat = () => {
  const { ticketId, User } = useParams();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState(false);
  const [active, setActive] = useState({});
  const [chat, setChat] = useState([]);
  const [id, setId] = useState();
  const [activeUsers, setActiveUsers] = useState();
  const [name, setName] = useState(null);
  const [msg, setMsg] = useState([]);
  const [currentMsg, setCurrentMsg] = useState(null);
  const [canUseEmoji, setCanUseEmoji] = useState(false);
  const [fcm, setFcm] = useState();

  const soc = useRef(io("https://dev-cardboardconnection.flynautstaging.com"));
  // const soc = useRef(io("http://localhost:5010"));
  const msgRef = useRef();

  useEffect(() => {
    console.log("socsocsoc",soc)
    if (msgRef.current) {
      msgRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [msgRef, msg]);

  useEffect(() => {
    console.log("yesssssssssssss")
    soc?.current.on("recieveMsg", (data) => {
      console.log("recieveMsgrecieveMsgrecieveMsg", data);
      setMsg([...msg, data]);
    });
    console.log("yesssssssssssss111")
  }, [currentMsg, msg]);

  const handleMsg = () => {
    console.log("activeUsers", activeUsers);

    soc?.current.emit("sendMsg", {
      userId: user._id,
      user: "jas",
      msg: currentMsg,
      reciever: activeUsers[0]?.socketId,
    });
    sendMsgToServer();
  };

  useEffect(() => {
    addUser();
    soc?.current.on("users", (users) => {
      // console.log("usersusersusersusers", users,"user._id",user._id);
      setActiveUsers(users.filter((usr) => usr.userId !== user._id));
    });
  }, []);

  // useEffect(() => {
  //   console.log(
  //     "actsuer",
  //     activeUsers.filter((usr) => usr.userId !== id)
  //   );
  // }, [activeUsers, id]);

  const addUser = () => {
    soc?.current.emit("addUser", {
      userId: user._id,
    });
  };

  useEffect(() => {
    console.log("uuuuuuuuuuuuuuuuu",User);
    // console.log(user);
    getChat();
  }, []);

  const getChat = () => {
    setLoading(true);
    axios
      .get(`/chatForAdmin/${User}/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setChat(res.data.data);
        if (res.data.data !== null) {
          getMessages(res.data.data._id);
        }
        setLoading(false);
      })
      .catch((res) => console.log(res));
  };

  const getMessages = (chatId) => {
    axios
      .get(`/messageForAdmin/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("msg", res);
        res.data.data && res.data.data.length > 0 && setMsg(res.data.data);
      })
      .catch((res) => console.log(res));
  };

  const startChat = () => {
    axios
      .post(
        `/chatForAdmin/${User}/${ticketId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChat(res.data);
      })
      .catch((res) => console.log(res));
  };

  // useEffect(() => {
  //   getMessages(chat._id);
  // }, [msg]);

  const sendMsgToServer = () => {
    axios
      .post(
        `/messageForAdmin/${id}/${chat._id}`,
        { message: currentMsg },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setMsg([...msg, res.data.data]);
        setCurrentMsg("");
      })
      .catch((res) => console.log(res));
  };

  const sendNoti = () => {
    console.log("fcmfcmfcmfcm",fcm)
    axios.post('/not/test',{
      fcmToken: fcm,
      title: "Reply of your ticket",
      body: "Reply of your ticket"
  },{
    headers: {
      "Authorization" : `Bearer ${token}`
    }
  })
  .then((res) => {
    console.log(res,"This is res")
  })
  .catch((err) => console.log(err))
  }

  return (
    <div style={{ padding: 0 }}>
      <Row gutter={[24, 24]}>
        <Col span={10} md={10} lg={10}>
          <Badge.Ribbon text="Open" color="green">
            <Card
              title="Ticket Title"
              actions={[
                <Badge>2023-5-34</Badge>,
                <Button type="primary">Mark as resolved</Button>,
                <></>,
                <></>,
                <></>,
              ]}
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Cupiditate dolorum voluptate alias laboriosam commodi velit, iure
              placeat rem id officiis repudiandae. Cumque cupiditate amet
              laborum a dolorem quod, provident ad dolore unde quasi! Rem
              dolorem cupiditate magnam repellendus ipsum sit! Harum totam unde
              minus molestias tempora, maiores eligendi! Quo, dolorem?
            </Card>
          </Badge.Ribbon>
        </Col>
        <Col span={14} md={14} lg={14}>
          <div
            style={{ height: "86vh", background: "#fff", position: "relative" }}
          >
            {loading ? (
              <div style={{ paddingTop: 200 }}>
                <center>
                  <Spin size="large" />

                  <p
                    style={{
                      color: "grey",
                      fontFamily: "Poppins",
                      padding: 10,
                    }}
                  >
                    Please wait...
                  </p>
                </center>
              </div>
            ) : (
              <Card
                title="Ticket Conversation"
                style={{
                  borderBottom: 0,
                  borderBottomLeftRadius: 0,
                  height: "70vh !important",
                  minHeight: "70vh",
                }}
              >
                <div className="flex flex-col w-full overflow-y-scroll h-[68vh]">
                  {!chat ? (
                    <div>
                      <center>
                        <Image width={150} src={StartChat} preview={false} />
                        <br />
                        <p>
                          No Conversation found for this ticket. <br />
                          click below to start a Conversation <br />
                          <br />
                        </p>
                        <Button onClick={() => startChat()} type="primary">
                          Start Conversation
                        </Button>
                      </center>
                    </div>
                  ) : (
                    msg.length > 0 &&
                    msg.map((cMsg) => (
                      <Message
                        msg={cMsg.message}
                        self={cMsg.userId === user._id}
                      />
                    ))
                  )}
                  
                  <div ref={msgRef} />
                  {/* <Message msg="I am good, how are you ?" self={true} /> */}
                  {/* <Message msg="Hi, how are you" />
                <Message msg="I am good, how are you ?" self={true} />
                <Message
                  msg="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate dolorum voluptate alias laboriosam commodi velit, iure placeat rem id officiis repudiandae. Cumque cupiditate amet laborum a dolorem quod, provident ad dolore unde quasi! Rem dolorem cupiditate magnam repellendus ipsum sit! Harum totam unde minus molestias tempora, maiores eligendi! Quo, dolorem?"
                  self={true}
                />
                <Message msg="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate dolorum voluptate alias laboriosam commodi velit, iure placeat rem id officiis repudiandae. Cumque cupiditate amet laborum a dolorem quod, provident ad dolore unde quasi! Rem dolorem cupiditate magnam repellendus ipsum sit! Harum totam unde minus molestias tempora, maiores eligendi! Quo, dolorem?" /> */}
                </div>
              </Card>
            )}
            <Row
              gutter={[12, 12]}
              style={{
                padding: 5,
                position: "absolute",
                width: "100%",
                bottom: 0,
                background: "#fff",
                borderBottom: "1px solid lightgrey",
                borderTop: "1px solid lightgrey",
              }}
            >
              <Col md="4" style={{}}>
                {canUseEmoji && (
                  <EmojiPicker
                    width={400}
                    height={400}
                    emojiStyle="apple"
                    position="absolute"
                    onEmojiClick={(e) =>
                      setCurrentMsg(`${currentMsg}${e.emoji}`)
                    }
                  />
                )}
                <SmileFilled
                  style={{ fontSize: 20, color: "grey" }}
                  onClick={() => setCanUseEmoji(!canUseEmoji)}
                />
              </Col>
              <Col md={20}>
                <input
                  placeholder="Start typing..."
                  className="focus:outline-none"
                  onChange={(e) => setCurrentMsg(e.target.value)}
                  value={currentMsg}
                  style={{
                    background: "#fff",
                    border: "none",
                    borderRadius: 0,
                    padding: 5,
                    width: "100%",
                  }}
                />
              </Col>
              <Col md={2} className="p-0">
                <Button
                  style={{
                    background: "#0000ff",
                    border: "none",
                    padding: 0,
                    width: 60,
                    borderRadius: 4,
                  }}
                  onClick={() => handleMsg()}
                >
                  <SendOutlined
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      position: "absolute",
                      top: 8,
                      left: 24,
                    }}
                  />
                </Button>
              </Col>
            </Row>
          </div>
          {/* 
          {users.map((usr) => {
            console.log(usr);

            return (
              <Button onClick={() => setActive(usr.socketId)}>
                {usr.userId}
              </Button>
            );
          })}

          <input
            placeholder="name"
            onChange={(e) => setName(e.target.value)}
            name="name"
          />

           <>
              <input
                placeholder="id"
                onChange={(e) => setId(e.target.value)}
                name="userId"
              />
              <button onClick={() => addUser()}>Add</button>
            </>
          
          <button
            onClick={() =>
              soc?.current.emit("addUser", {
                userId: id,
              })
            }
          >
            Add User
          </button>

          <input
            placeholder="msg"
            onChange={(e) => setMsg(e.target.value)}
            name="msg"
          />

          <button
            onClick={() => {
              console.log(active);
              soc?.current.emit("sendMsg", {
                userId: id,
                user: name,
                msg: msg,
                reciever: active,
              });
            }}
          >
            Emit
          </button> */}
        </Col>
      </Row>
    </div>
  );
};

export { Chat };
