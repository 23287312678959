import React, { useEffect, useState } from "react";
import { Button, message, Modal, Table, Tooltip } from "antd";
import axios from "axios";
import { TicketStatus } from "../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { MessageFilled } from "@ant-design/icons";
import { Link, Navigate } from "react-router-dom";

const Tickets = () => {
    const token = localStorage.getItem("token");
    const [data, setData] = useState([]);

    const getData = () => {
        axios
            .get("/allTickets", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log(res.data.data, "This is res");
                setData(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getData();
    }, []);

    // console.log(data,"this is ticket data");

    const columns = [
        {
            key: "1",
            title: "Status",
            render: (data) => (
                <p>
                    {data.status === TicketStatus.Open
                        ? "Open"
                        : data.status === TicketStatus.Closed
                        ? "Closed"
                        : "Other"}
                </p>
            ),
        },
        {
            key: "2",
            title: "Ticket ID",
            render: (data) => <p>{data.ticketId}</p>,
        },
        {
            key: "3",
            title: "Title",
            render: (data) => <p>{data.title}</p>,
        },
        {
            key: "4",
            title: "Content",
            render: (data) => <p>{data.content}</p>,
        },
        {
            key: "5",
            title: "User",
            render: (data) => <p>{data.User}</p>,
        },
        {
            key: "6",
            title: "Created At",
            render: (data) => (
                <p>{new Date(data.createdAt).toLocaleDateString()}</p>
            ),
        },
        {
            key: "7",
            title: "Action",
            render: (data) => (
                <div className="flex items-center gap-7">
                    {data.status === TicketStatus.Open && !1 && (
                        // <Link to={`/admin/chat/${data._id}/${data.User}`}>
                        //   <MessageFilled style={{ color: "#0000FF" }} />
                        // </Link>
                        <Link to={`/admin/realchat/${data._id}/${data.User}`}>
                            <MessageFilled style={{ color: "#0000FF" }} />
                        </Link>
                    )}
                    {data.status === TicketStatus.Open ? (
                        <Button onClick={() => handleResolve(data)}>
                            Resolve Ticket
                        </Button>
                    ) : null}
                    {data.status === TicketStatus.Closed ? (
                        <Button type="primary" onClick={() => handleOpen(data)}>
                            Open Ticket
                        </Button>
                    ) : null}
                </div>
            ),
        },
    ];

    const handleResolve = (item) => {
        Modal.confirm({
            title: "Are you sure, you want to resolve this ticket?",
            okText: "Yes, Resolve",
            onOk: () => {
                axios
                    .patch(
                        `/ticket/resolve/${item._id}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((res) => {
                        message.success("Ticket resolved!");
                        getData();
                    })
                    .catch((err) => console.log(err));
            },
        });
    };

    const handleOpen = (item) => {
        Modal.confirm({
            title: "Are you sure, you want to open this ticket?",
            okText: "Yes, Open",
            onOk: () => {
                axios
                    .patch(
                        `/ticket/open/${item._id}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((res) => {
                        message.success("Ticket opened!");
                        getData();
                    })
                    .catch((err) => console.log(err));
            },
        });
    };

    return (
        <div>
            <h1 className="mb-10 text-[20px] text-[#307FE2] font-semibold">
                Tickets Data
            </h1>
            <Table dataSource={data} columns={columns} />
        </div>
    );
};

export default Tickets;
