import { Routes, Route } from "react-router-dom";
import "./App.css";
import Admin from "./components/Admin";
import Advertisers from "./components/Advertisers";
import { Chat } from "./components/chat";
import { Realchat } from "./components/realchat";
import AboutUs from "./components/cms components/AboutUs";
import Privacy from "./components/cms components/Privacy";
import Terms from "./components/cms components/Terms";
import Index from "./components/Index";
import Login from "./components/Login.jsx";
import Tickets from "./components/Tickets";
import Users from "./components/Users";

function App() {
  const token = localStorage.getItem("token");

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {token ? (
        // <Route path='/admin' element={<Admin />} />
        <>
          <Route path="/admin" element={<Index />}>
            <Route path="user" element={<Users />} />
            <Route path="ticket" element={<Tickets />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="chat/:ticketId/:User" element={<Chat />} />
            <Route path="realchat/:ticketId/:User" element={<Realchat />} />
            <Route path="advertise" element={<Advertisers />} />
          </Route>
        </>
      ) : null}
    </Routes>
  );
}

export default App;
