import React from "react";

const Message = (props) => {
  const { msg, self } = props;

  return (
    <div className={`flex flex-col w-full ${self && "items-end"}`}>
      <div
        className={`flex  ${
          self ? "bg-blue-100" : "bg-gray-200"
        }  m-2 p-2 rounded-lg`}
        style={{
          maxWidth: "56vh",
          minWidth: "20vh",
          width: "fit-content",
        }}
      >
        {msg}
      </div>
    </div>
  );
};

export { Message };
