import { SendOutlined, SmileFilled } from "@ant-design/icons/lib/icons";
import { Badge, Button, Card, Col, Empty, Image, Row, Spin } from "antd";
import EmojiPicker from "emoji-picker-react";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { io } from "socket.io-client";
import axios from "axios";
import { useParams } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
const moment = require('moment') 

const firebaseConfig = {
  apiKey: "AIzaSyB4WUP7rNBjupNxqXYFWDgPRmzplLgJJFI",
  authDomain: "cardboard-connection.firebaseapp.com",
  databaseURL: "https://cardboard-connection-default-rtdb.firebaseio.com",
  projectId: "cardboard-connection",
  storageBucket: "cardboard-connection.appspot.com",
  messagingSenderId: "554872871474",
  appId: "1:554872871474:web:5757daaffa09ca20b4ba2b",
  measurementId: "G-8V1JKDJ3HZ",
};

firebase.initializeApp(firebaseConfig);

const chatRef = firebase.database().ref("chat");

// const socket = io('http://localhost:5010');
// const socket = io("https://dev-cardboardconnection.flynautstaging.com");
const Realchat = () => {
  const { ticketId, User } = useParams();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  // const [loading, setLoading] = useState(true);
  // const [messages, setMessages] = useState([]);
  // const [message, setMessage] = useState('');
  // const [typing, setTyping] = useState('');
  // const [ticketId, setTicketId] = useState(ticketID);
  // const messagesEndRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [ticketDetail, setTicketDetail] = useState({});
  // useEffect(() => {
  //   const messagesRef = firebase.database().ref('messages');
  //   console.log("jshhsjs",messagesRef)
  //   messagesRef.on('value', (snapshot) => {
  //     const messageList = [];
  //     snapshot.forEach((child) => {
  //       messageList.push({
  //         id: child.key,
  //         ...child.val(),
  //       });
  //     });
  //     setMessages(messageList);
  //   });
  // }, []);

  useEffect(() => {
    console.log("amiiiittt", ticketId);
    const messagesRef = firebase.database().ref(`messages/${ticketId}`);
    messagesRef.on("value", (snapshot) => {
      const messageList = [];
      snapshot.forEach((child) => {
        messageList.push({
          id: child.key,
          ...child.val(),
        });
      });
      console.log("messageList", messageList);
      setMessages(messageList);
    });
  }, [ticketId]);

  useEffect(() => {
    getTicketDetail();
  }, []);

  const getTicketDetail = () => {
    axios
      .get(`/ticket/get/${ticketId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setTicketDetail(res.data.data);
      })
      .catch((res) => console.log(res));
  };

  // const sendMessage = () => {
  //   const messagesRef = firebase.database().ref('messages');
  //   const newMessage = {
  //     text: message,
  //     ticketId: ticketId,
  //     reciever : User,
  //     from: "Admin"
  //   };
  //   messagesRef.push(newMessage);
  //   setMessage('');
  // };

  const sendMessage = () => {
    const messageRef = firebase.database().ref(`messages/${ticketId}`);
    const newMessage = {
      text: message,
      type: "text",
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      sender: "Admin",
      reciever: User,
    };
    messageRef.push(newMessage);
    setMessage("");
  };

  // const scrollToBottom = () => {

  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [messages]);

  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  // const handleJoin = (e) => {
  //   e.preventDefault();
  //   socket.emit('join', ticketId);
  // };

  // const handleSendMessage = (e) => {
  //   e.preventDefault();
  //   socket.emit('message', { message }, ticketId);
  //   setMessage('');
  // };

  // const handleTyping = (e) => {
  //   if (e.target.value) {
  //     socket.emit('typing', 'Someone is typing...', ticketId);
  //   } else {
  //     socket.emit('typing', '', ticketId);
  //   }
  //   setMessage(e.target.value);
  // };

  // socket.on('message', ({ sender, message }) => {
  //   setMessages((messages) => [...messages, { sender, message }]);
  // });

  // socket.on('typing', (data) => {
  //   setTyping(data);
  // });

  return (
    <div style={{ padding: 0 }}>
      <Row gutter={[24, 24]}>
        <Col span={10} md={10} lg={10}>
          <Badge.Ribbon text="Open" color="green">
            <Card
              title={ticketDetail.title}
              actions={[
                <Badge>{moment(ticketDetail?.createdAt).format('MMMM DD YYYY')}</Badge>,
                <Button type="primary">Mark as resolved</Button>,
                <></>,
                <></>,
                <></>,
              ]}
            >
              {ticketDetail.content}
            </Card>
          </Badge.Ribbon>
        </Col>
        <Col span={14} md={14} lg={14}>
          <div
            style={{ height: "86vh", background: "#fff", position: "relative" }}
          >
            <Card
              title="Ticket Conversation"
              style={{
                borderBottom: 0,
                borderBottomLeftRadius: 0,
                height: "70vh !important",
                minHeight: "70vh",
              }}
            >
              <div className="flex flex-col w-full overflow-y-scroll h-[68vh]">
                <ul>
                  {messages.map((message) => (
                    <div
                      className={`flex flex-col w-full ${
                        message.sender == "Admin" && "items-end"
                      }`}
                    >
                      <div
                        className={`flex  ${
                          message.sender == "Admin"
                            ? "bg-blue-100"
                            : "bg-gray-200"
                        }  m-2 p-2 rounded-lg`}
                        style={{
                          maxWidth: "56vh",
                          minWidth: "20vh",
                          width: "fit-content",
                        }}
                      >
                        {message.type == "text" ? 
                        <li key={message.id}>{message.text}</li> :
                        <img src={message.text} alt="User avatar" />
                        }
                        
                      </div>
                    </div>
                  ))}
                </ul>
                {/* <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage();
                  }}
                > */}
                {/* </form> */}
              </div>
            </Card>
            <Row
              gutter={[12, 12]}
              style={{
                padding: 5,
                position: "absolute",
                width: "100%",
                bottom: 0,
                background: "#fff",
                borderBottom: "1px solid lightgrey",
                borderTop: "1px solid lightgrey",
              }}
            >
              {/* <Col md="4" style={{}}>
                {canUseEmoji && (
                  <EmojiPicker
                    width={400}
                    height={400}
                    emojiStyle="apple"
                    position="absolute"
                    onEmojiClick={(e) =>
                      setCurrentMsg(`${currentMsg}${e.emoji}`)
                    }
                  />
                )}
                <SmileFilled
                  style={{ fontSize: 20, color: "grey" }}
                  onClick={() => setCanUseEmoji(!canUseEmoji)}
                />
              </Col> */}
              <Col md={20}>
                    <input
                      type="text"
                      value={message}
                      placeholder="Start typing..."
                      className="focus:outline-none"
                      onChange={(e) => setMessage(e.target.value)}
                      style={{
                        background: "#fff",
                        border: "none",
                        borderRadius: 0,
                        padding: 5,
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col md={2} className="p-0">
                    <Button
                      type="submit"
                      style={{
                        background: "#0000ff",
                        border: "none",
                        padding: 0,
                        width: 60,
                        borderRadius: 4,
                      }}
                      onClick={(e) => {
                    e.preventDefault();
                    sendMessage();
                  }}
                    >
                      <SendOutlined
                        style={{
                          color: "red",
                          fontSize: "20px",
                          position: "absolute",
                          top: 8,
                          left: 24,
                        }}
                      />
                    </Button>
                    {/* <button type="submit">Send</button> */}
                  </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export { Realchat };
