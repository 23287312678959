import React, { useEffect, useState } from "react";
import { message } from "antd";
import logo from "../assets/mainLogo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ email: "", password: "" });

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`/adminLogin`, {
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        message.success(res.data.message);
        navigate("/admin/user");
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex justify-center items-center h-[100vh]">
        <div className="border-4 p-8 rounded-2xl text-[18px] flex flex-col gap-6 w-[30rem]">
          <div className="w-fit mx-auto mb-3">
            <img src={logo} alt="" />
          </div>
          <div>
            <h1>Email Address</h1>
            <input
              value={data.email}
              className="mt-1 w-full border p-1 focus:outline-[#307FE2] rounded"
              type="email"
              placeholder="Enter email address"
              required
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
          <div>
            <h1>Password</h1>
            <input
              value={data.password}
              className="mt-1 w-full border p-1 focus:outline-[#307FE2] rounded"
              type="password"
              placeholder="Enter password"
              required
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
          </div>
          <button className="w-full mt-5 hover:opacity-80 bg-[#307FE2] text-white text-[18px] font-semibold p-2 rounded">
            Login
          </button>
        </div>
      </div>
    </form>
  );
};

export default Login;
