import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { Interweave } from 'interweave';
import { message } from 'antd';

const Terms = () => {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);

  const getData = () => {
    axios.get('/terms',{
    headers:{
      "Authorization" : `Bearer ${token}`
      }
    })
    .then((res) => {
      // console.log(res.data,"This is res");
      setData(res.data.data)
    })
    .catch((err) => console.log(err))
  }
  
  useEffect(() => {
    getData()
  }, [])

  const config = {
    placeholder: "Start typing....."
  }

  const handleSubmit = () => {
    // console.log(content,"This is content")
    axios.post('/add-terms',{
      terms: content,
    },{
      headers: {
        "Authorization" : `Bearer ${token}`
      }
    })
    .then((res) => {
      console.log(res,"This is res")
      message.success("Submitted Successfully!")
      getData();
    })
    .catch((err) => console.log(err))
  }

  return (
    <div className='flex flex-col gap-10'>
      <h1 className='text-[20px] text-[#307FE2] font-semibold'>Terms & Conditions</h1>
      <div>
        {data.map((item) => {
          return(
            <div key={item._id}><Interweave content={item.terms} /></div>
          )
        })}
      </div>
        <JoditEditor
        className=''
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => {}}
      />
      <button className='w-fit mt-5 hover:opacity-80 bg-[#307FE2] text-white text-[18px] font-semibold p-2 px-5 rounded' onClick={handleSubmit}>
        Submit
      </button>
    </div>
  )
}

export default Terms