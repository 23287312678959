import React, { useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Image,
    Input,
    message,
    Modal,
    Select,
    Spin,
    Switch,
    Table,
    Tooltip,
    Upload,
} from "antd";

import dayjs from "dayjs";
import axios from "axios";

import { UploadOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const Advertisers = () => {
    const token = localStorage.getItem("token");
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [singleDate, setSingleDate] = useState(false);
    const [bannerID, setBannerID] = useState();
    const [date, setDate] = useState([]);
    const [bannerImg, setBannerImg] = useState();
    const [bannerURL, setBannerURL] = useState();
    const [loading, setLoading] = useState(false);
    const [openBanner, setOpenBanner] = useState(false);
    const [modifyBannerModal, setModifyBannerModal] = useState(false);
    const [bannerData, setBannerData] = useState();
    const [fcm, setFcm] = useState();

    const [dataToUpdate, setUpdateData] = useState({});
    const [dateDefValue, setDateDefValue] = useState("");
    const [dateRangeDefValue, setDateRangeDefValue] = useState("");

    const getData = () => {
        setLoading(true);
        axios
            .get("/allAdvertisers", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log(res.data.data,"This is res");
                setBannerImg([]);
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getData();
    }, []);

    const uploadBanner = () => {
        setLoading(true);
        const form = new FormData();
        bannerImg.map((item) => {
            form.append("banner", item.originFileObj);
        });
        // form.append("url", bannerURL);
        // console.log(form, "This is form");
        axios
            .patch(`/uploadBanner/advertisers/${bannerData._id}`, form, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setOpenBanner(false);
                setModifyBannerModal(false);
                setBannerImg([]);
                setBannerURL("");
                getData();
                setLoading(false);
                message.success("Banner uploaded successfully!");
            })
            .catch((err) => console.log(err));
    };

    // console.log(data,bannerURL,"this is data");

    const columns = [
        {
            key: "1",
            title: "Business Details",
            render: (data) => (
                <div>
                    <p>Business: {data.businessName}</p>
                    <p>Contact: {data.contactName}</p>
                    <p>Email: {data.email}</p>
                    <p>Phone: {data.phone}</p>
                </div>
            ),
        },
        {
            key: "7",
            title: "Zone",
            render: (data) => <p>{data.zone}</p>,
        },
        {
            key: "2",
            title: "Banner",
            render: (data) => (
                <>
                    {!data.banner ? (
                        <Button
                            className="border-0"
                            icon={<UploadOutlined />}
                            onClick={() => {
                                setBannerData(data);
                                setOpenBanner(true);
                            }}
                        ></Button>
                    ) : (
                        <button
                            onClick={() => {
                                setBannerData(data);
                                setBannerURL(data.url);
                                setModifyBannerModal(true);
                            }}
                        >
                            <img width={60} src={data.banner} alt="" />
                        </button>
                    )}
                </>
            ),
        },
        {
            key: "3",
            title: "Start Date",
            render: (data) => (
                <p
                    onClick={(e) => {
                        data.startTime && setDateDefValue(data.startTime);
                        setBannerID(data._id);
                        setUpdateData({ action: "startTime" });
                        setSingleDate(true);
                    }}
                >
                    {data.startTime || "Set date"}
                </p>
            ),
        },
        {
            key: "4",
            title: "End Date",
            render: (data) => (
                <p
                    onClick={(e) => {
                        data.endTime && setDateDefValue(data.endTime);
                        setBannerID(data._id);
                        setUpdateData({ action: "endTime" });
                        setSingleDate(true);
                    }}
                >
                    {data.endTime || <Button type="dashed">Set date</Button>}
                </p>
            ),
        },
        {
            key: "5",
            title: "Status",
            render: (data) => (
                <p>
                    {data.isActive === false
                        ? "Inactive"
                        : checkStatus(data.startTime, data.endTime)}
                </p>
            ),
        },
        {
            key: "6",
            title: "Active",
            render: (data) => (
                <div>
                    <Button
                        type="primary"
                        ghost={true}
                        style={{ marginRight: "5px" }}
                        onClick={(e) => {
                            if (data.startTime && data.endTime) {
                                setDateRangeDefValue([
                                    dayjs(data.startTime),
                                    dayjs(data.endTime),
                                ]);
                            } else if (data.startTime) {
                                setDateRangeDefValue(dayjs[data.startTime]);
                            } else {
                                setDateRangeDefValue("");
                            }
                            setBannerID(data._id);
                            setUpdateData(data);
                            setOpen(!open);
                        }}
                    >
                        Edit
                    </Button>
                    <Switch
                        checked={data.isActive}
                        loading={loading}
                        onChange={(checked, e) => switchChange(checked, data)}
                    />
                </div>
            ),
        },
    ];

    const checkStatus = (startTime, endTime) => {
        let current = new Date();
        let start = new Date(startTime);
        let end = new Date(endTime);
        if (current > start && current < end) return "Live";
        else if (current < start) return "Scheduled";
        else if (current > end) return "Expired";
    };

    const switchChange = (checked, data) => {
        setBannerID(data._id);
        setUpdateData(data);

        if (data.startTime && data.endTime) {
            setDateRangeDefValue([dayjs(data.startTime), dayjs(data.endTime)]);
        } else if (data.startTime) {
            setDateRangeDefValue(dayjs[data.startTime]);
        } else {
            setDateRangeDefValue("");
        }

        // console.log("data.User.fcmToken",data)
        setFcm(data.User.fcmToken);
        if (checked === true) {
            setOpen(true);
        } else {
            deactivateBanner(data._id);
        }
    };

    const onChange = (value, dateString) => {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        setDate(dateString);
    };

    const activateBanner = () => {
        // setLoading(true);
        // axios
        //     .patch(
        //         `/advertisers/updateStatus/${bannerID}`,
        //         {
        //             isActive: true,
        //             startTime: date[0],
        //             endTime: date[1],
        //         },
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         }
        //     )
        //     .then((res) => {
        //         message.success("Banner activated!");
        //         setOpen(false);
        //         getData();
        //         setLoading(false);
        //     })
        //     .catch((res) => console.log(res));

        updateAdv({
            ...dataToUpdate,
            isActive: true,
            startTime: date[0],
            endTime: date[1],
        });
        message.success("Banner activated!");
    };

    const updateAdv = (d = {}) => {
        setLoading(true);
        setDateDefValue("");
        axios
            .patch(`/advertisers/updateStatus/${bannerID}`, d, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                message.success("Data updated");
                setLoading(false);
                setSingleDate(false);
                setOpen(false);
                getData();
            });
    };

    const sendNoti = () => {
        console.log("fcmfcmfcmfcm", fcm);
        axios
            .post(
                "/not/test",
                {
                    fcmToken: fcm,
                    title: "Banner Activated",
                    body: "Banner Activated",
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res, "This is res");
                // message.success("Notify Successfully!")
                // getData();
            })
            .catch((err) => console.log(err));
    };

    const deactivateBanner = (id) => {
        setLoading(true);
        axios
            .patch(
                `/advertisers/updateStatus/${id}`,
                {
                    isActive: false,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                message.success("Banner deactivated!");
                getData();
                setLoading(false);
            })
            .catch((res) => console.log(res));
    };

    return (
        <div>
            <h1 className="mb-10 text-[20px] text-[#307FE2] font-semibold">
                Advertisers Data
            </h1>
            <Spin spinning={loading}>
                <Table
                    dataSource={data}
                    rowKey={(rec) => rec._id}
                    columns={columns}
                />
            </Spin>

            <Modal
                open={singleDate}
                onCancel={() => setSingleDate(false)}
                onOk={(e) => {
                    updateAdv(dataToUpdate);
                }}
            >
                <h4>Business: {bannerData?.businessName}</h4>
                <h1>Pick a date</h1>
                <DatePicker
                    format={`YYYY-MM-DD`}
                    value={dateDefValue && dayjs(dateDefValue)}
                    onChange={(e, val) => {
                        const fieldName = dataToUpdate.action;
                        setUpdateData({ [fieldName]: val });
                    }}
                />
            </Modal>

            <Modal
                title=""
                open={open}
                onCancel={() => setOpen(false)}
                onOk={activateBanner}
            >
                <h4>Business: {dataToUpdate?.businessName}</h4>
                <h1 className="font-bold mb-5">Select Date and Time</h1>
                <RangePicker
                    picker="date"
                    value={dateRangeDefValue}
                    format="YYYY-MM-DD"
                    onChange={onChange}
                />

                <h2>Banner URL</h2>
                <Input
                    type="text"
                    value={dataToUpdate?.url}
                    name="url"
                    onChange={(e) =>
                        setUpdateData({
                            ...dataToUpdate,
                            url: e.target.value,
                        })
                    }
                />
                <h2>Zone</h2>
                <Select
                    name="advZone"
                    value={dataToUpdate?.zone || ""}
                    onChange={(val) =>
                        setUpdateData({
                            ...dataToUpdate,
                            zone: val,
                        })
                    }
                    options={[
                        { value: "", label: "Pick a zone" },
                        { value: 1, label: "Zone 1" },
                        { value: 2, label: "Zone 2" },
                        { value: 3, label: "Zone 3" },
                    ]}
                />
            </Modal>

            <Modal
                title="Upload your Banner and URL"
                open={openBanner}
                onCancel={() => setOpenBanner(false)}
                onOk={uploadBanner}
                okText="Submit"
            >
                <h4>Business: {bannerData?.businessName}</h4>
                <div className="flex flex-col gap-5">
                    <Upload
                        fileList={bannerImg}
                        multiple={false}
                        listType="picture"
                        onChange={(e) => setBannerImg(e.fileList)}
                        beforeUpload={() => {
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                    {/* <Input
                        value={bannerURL}
                        onChange={(e) => setBannerURL(e.target.value)}
                        placeholder="Enter URL here"
                    /> */}
                </div>
            </Modal>
            <Modal
                title="Edit your Banner and URL"
                open={modifyBannerModal}
                onCancel={() => setModifyBannerModal(false)}
                onOk={uploadBanner}
                okText="Submit"
            >
                <h4>Business: {bannerData?.businessName}</h4>
                <div className="flex flex-col gap-5">
                    <img className="w-full" src={bannerData?.banner} alt="" />
                    <Upload
                        fileList={bannerImg}
                        multiple={false}
                        listType="picture"
                        onChange={(e) => setBannerImg(e.fileList)}
                        beforeUpload={() => {
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                    {/* <Input
                        value={bannerURL}
                        onChange={(e) => setBannerURL(e.target.value)}
                        placeholder="Enter URL here"
                    /> */}
                </div>
            </Modal>
        </div>
    );
};

export default Advertisers;
