import React, { useEffect, useState } from 'react'
import { Image, message, Modal, Table, Tooltip } from 'antd'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Users = () => {
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [banModal, setBanModal] = useState(false);

  const getData = () => {
    axios.get('/user/get-all',{
    headers:{
      "Authorization" : `Bearer ${token}`
      }
    })
    .then((res) => {
      // console.log(res.data.data,"This is res");
      setData(res.data.data)
    })
    .catch((err) => console.log(err))
  }
  
  useEffect(() => {
    getData()
  }, [])
  
  console.log(data,"this is data");
  
  const columns = [
    {
      key: '7',
      title: 'Profile Image',
      render: (data) => <Image width={60} src={data.profileImage} alt="" />
    },
    {
      key: '1',
      title: 'Email',
      render: (data) => <p>{data.email}</p>
    },
    {
      key: '2',
      title: 'Phone',
      render: (data) => <p>{data.phone}</p>
    },
    {
      key: '3',
      title: 'DOB',
      render: (data) => <p>{data.dob}</p>
    },
    {
      key: '4',
      title: 'Bio',
      render: (data) => <p>{data.bio}</p>
    },
    {
      key: '5',
      title: 'Address',
      render: (data) => <p>{data.address}</p>
    },
    {
      key: '6',
      title: 'Action',
      render: (data) => (
        <div className='flex items-center gap-7'>
          {data.status === 0 ? <Tooltip title='Ban User'>
            <button onClick={() => handleBan(data)}>
              <FontAwesomeIcon icon={faX} />
            </button>
          </Tooltip> : null}
          {data.status === 1 ? <Tooltip title='Restore User'>
            <button onClick={() => handleRestore(data)}>
              <FontAwesomeIcon icon={faRotateLeft} />
            </button>
          </Tooltip> : null}
          <Tooltip title="Delete User">
            <button className='text-red-500' onClick={() => handleDelete(data)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </Tooltip>
        </div>
      )
    },
  ];

  const handleBan = (item) => {
    Modal.confirm({
      title:"Are you sure, you want to ban this user?",
      okText:"Yes, Ban",
      onOk: () => {
        axios.delete(`/user/ban/${item._id}`,{
          headers:{
            "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          message.success("User banned!")
          getData()
        })
        .catch((err) => console.log(err))
      }
    })
  }

  const handleRestore = (item) => {
    Modal.confirm({
      title:"Are you sure, you want to restore this user?",
      okText:"Yes, Restore",
      onOk: () => {
        axios.patch(`/user/restore/${item._id}`,{},{
          headers:{
            "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          message.success("User restored!")
          getData()
        })
        .catch((err) => console.log(err))
      }
    })
  }

  const handleDelete = (item) => {
    Modal.confirm({
      title:"Are you sure, you want to delete this user?",
      okText:"Yes, Delete",
      onOk: () => {
        axios.delete(`/user/delete/${item._id}`,{
          headers:{
            "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
          message.success("User deleted!")
          getData()
        })
        .catch((err) => console.log(err))
      }
    })
  }

  return (
    <div>
      <h1 className='mb-10 text-[20px] text-[#307FE2] font-semibold'>Users Data</h1>
      <Table dataSource={data} columns={columns} />
      {/* <Modal 
        title='Testing'
        okText="Yes, Ban User"
        icon= {<ExclamationCircleOutlined />}
        open={banModal} 
        onCancel={() => setBanModal(false)}>
      </Modal> */}
    </div>
  )
}

export default Users